.mau-table {
  tr,
  th,
  td,
  table {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }

  width: 80%;
  font-size: 0.9rem;
}
